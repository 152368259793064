import React, { useEffect, useRef, useState } from 'react';
import {
  ContactSupport,
  ContactUs,
  Footer,
  ManagerAdmin,
  Meta,
  StickyHeader,
} from '../components';
import MainFeature from '../components/landing/MainFeature';
import styles from '../styles/LandingPage.module.css';

const LandingPage = () => {
  const [isSticky, setSticky] = useState(false);
  const ref = useRef(null);
  const handleScroll = () => {
    if (ref.current) {
      setSticky(ref.current.getBoundingClientRect().top <= -window.innerHeight);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  return (
    <>
      <Meta />
      <div className={styles.LandingPage}>
        <StickyHeader isSticky={isSticky} ref={ref} />
        <ManagerAdmin
          // goToManage={() => history.push(`/manage`)}
          styles={styles}
        />
        <MainFeature styles={styles} />
        <ContactSupport />
        <ContactUs />
        <Footer />
      </div>
    </>
  );
};

export default LandingPage;
